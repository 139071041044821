import dynamic from 'next/dynamic';
import { Fragment } from 'react';

// Utils
import hasBlock from './hasBlock';
import getApartmentsByStay from './getApartmentsByStayType';

// BLOCKS
const TextBlock = dynamic(() => import('../../components/molecules/TextBlock/TextBlock'));
const TitleBlock = dynamic(() => import('../../components/atoms/TitleBlock/TitleBlock'));
const OneColumnBlock = dynamic(() => import('../../components/atoms/OneColumnBlock/OneColumnBlock'));
const TwoColumnsBlock = dynamic(() => import('../../components/atoms/TwoColumnsBlock/TwoColumnsBlock'));
const TitleAccordion = dynamic(() => import('../../components/molecules/TitleAccordion/TitleAccordion'));
const HeroSectionBlock = dynamic(() => import('../../components/organisms/HeroSectionBlock/HeroSectionBlock'));
const ImageBlock = dynamic(() => import('../../components/atoms/ImageBlock/ImageBlock'));
const AccordionBlock = dynamic(() => import('../../components/molecules/AccordionBlock/AccordionBlock'));
const CarouselBlock = dynamic(() => import('../../components/atoms/CarouselBlock/CarouselBlock'));
const AccordionDynamicBlock = dynamic(() => import('../../components/organisms/AccordionDynamicBlock/AccordionDynamicBlock'));
const ListBlock = dynamic(() => import('../../components/atoms/ListBlock/ListBlock'));
const AppartmentsBlock = dynamic(() => import('../../components/molecules/AppartmentsBlock/AppartmentsBlock'));
const ActusBlock = dynamic(() => import('../../components/molecules/ActusBlock/ActusBlock'), { ssr: false });
const SocialMediasBlock = dynamic(() => import('../../components/molecules/SocialMediasBlock/SocialMediasBlock'));
const ServicesBlock = dynamic(() => import('../../components/organisms/ServicesBlock/ServicesBlock'));
const PresentationBlock = dynamic(() => import('../../components/molecules/PresentationBlock/PresentationBlock'));
const KeyFiguresBlock = dynamic(() => import('../../components/atoms/KeyFiguresBlock/KeyFiguresBlock'));
const ResidencesBlock = dynamic(() => import('../../components/molecules/ResidencesBlock/ResidencesBlock'));
const TextBlockAlternative = dynamic(() => import('../../components/molecules/TextBlockAlternative/TextBlockAlternative'));
const SearchBar = dynamic(() => import('../../components/molecules/SearchBar/SearchBar'));
const MapBlock = dynamic(() => import('../../components/atoms/MapBlock/MapBlock'), { ssr: false });

const renderBlock = ({ data, type }, additionalInfos) => {
  switch (type) {
    case 'Module une colonne':
      return (
        <OneColumnBlock>
          {data?.['first-column-blocks']?.map((block, index) => (
            <Fragment key={`${block.type}-${index}`}>
              {renderBlock(block, additionalInfos)}
            </Fragment>
          ))}
        </OneColumnBlock>
      );
    case 'Module deux colonnes': {
      const firstHasImage = hasBlock(data?.['first-column-blocks'], 'Bloc media');
      const secondHasImag = hasBlock(data?.['second-column-blocks'], 'Bloc media');
      return (
        <TwoColumnsBlock>
          <div data-column="first-column-blocks" data-has-image={`${secondHasImag}`}>
            {data?.['first-column-blocks']?.map((block, index) => (
              <Fragment key={`${block.type}-${index}`}>
                {renderBlock(block, additionalInfos)}
              </Fragment>
            ))}
          </div>
          <div data-column="second-column-blocks" data-has-image={`${firstHasImage}`}>
            {data?.['second-column-blocks']?.map((block, index) => (
              <Fragment key={`${block.type}-${index}`}>
                {renderBlock(block, additionalInfos)}
              </Fragment>
            ))}
          </div>
        </TwoColumnsBlock>
      );
    }
    case 'Bloc accordéon':
      return (
        <AccordionBlock
          list={data?.list}
          theme={data?.theme}
        />
      );
    case 'Bloc accordéon dynamique':
      return (
        <AccordionDynamicBlock
          title={data?.title}
          subtitle={data?.subtitle}
          list={data?.list}
          theme={data?.theme}
        />
      );
    case 'Bloc actus': {
      return (
        <ActusBlock
          title={data?.title}
          subtitle={data?.subtitle}
          tags={data?.tags}
          theme={data?.theme}
        />
      );
    }
    case 'Bloc de présentation des appartements': {
      const apartments = getApartmentsByStay(additionalInfos?.activity, additionalInfos?.residence);
      return (
        <AppartmentsBlock
          title={data?.title}
          subtitle={data?.subtitle}
          apartments={apartments}
          isLongStay={additionalInfos?.residence?.displayLongStay}
          isMediumStay={additionalInfos?.residence?.displayMediumStay}
          activity={additionalInfos?.activity}
          displayContact={additionalInfos?.displayContact}
          theme={data?.theme}
        />
      );
    }
    case 'Bloc carousel':
      return (
        <CarouselBlock
          medias={data?.medias}
          theme={data?.theme}
          size={data?.size}
        />
      );
    case 'Bloc titre accordéon':
      return (
        <TitleAccordion
          title={data?.title}
          subtitle={data?.subtitle}
          list={data?.list}
          theme={data?.theme}
        />
      );
    case 'Bloc hero section':
      return (
        <HeroSectionBlock
          title={data?.title}
          content={data?.content}
          label={data?.label}
          media={data?.media}
          cities={data?.cities}
          regions={data?.regions}
          departments={data?.departments}
          youtube={data?.youtube}
        />
      );
    case 'Bloc trois colonnes':
      return (
        <KeyFiguresBlock
          items={data?.columns}
          theme={data?.theme}
        />
      );
    case 'Bloc liste':
      return (
        <ListBlock
          list={data?.list}
          theme={data?.theme}
        />
      );
    case 'Bloc map':
      return (
        <MapBlock
          content={data?.content}
          origin={additionalInfos?.residence?.latitude
            && additionalInfos?.residence?.longitude ? {
              lat: +additionalInfos.residence.latitude,
              lng: +additionalInfos.residence.longitude,
            } : null}
          address={additionalInfos?.residence?.address}
          theme={data?.theme}
        />
      );
    case 'Bloc media':
      return (
        <ImageBlock
          url={data?.media?.url}
          description={data?.media?.description}
          youtube={data?.youtube}
          theme={data?.theme}
        />
      );
    case 'Bloc présentation':
      return (
        <PresentationBlock
          title={data?.title}
          phone={additionalInfos?.phone}
          residence={additionalInfos?.residence?.nameShort}
          address={additionalInfos?.residence?.address}
          full={additionalInfos?.residence?.full}
          openingDate={additionalInfos?.residence?.openingDate}
          bookingUrl={additionalInfos?.bookingUrl}
          displayBooking={additionalInfos?.displayBooking}
          displayContact={additionalInfos?.displayContact}
          pmsId={additionalInfos?.residence?.pmsId}
          theme={data?.theme}
        />
      );
    case 'Bloc présentation résidences':
      return (
        <ResidencesBlock
          title={data?.title}
          subtitle={data?.subtitle}
          residences={data?.residences}
          theme={data?.theme}
        />
      );
    case 'Bloc services':
      return (
        <ServicesBlock
          title={data?.title}
          subtitle={data?.subtitle}
          content={data?.content}
          services={data?.services}
          theme={data?.theme}
        />
      );
    case 'Bloc réseaux sociaux':
      return (
        <SocialMediasBlock
          title={data?.title}
          subtitle={data?.subtitle}
          theme={data?.theme}
          posts={data?.posts}
        />
      );
    case 'Bloc texte':
      if (additionalInfos?.useAlternativeTextBlock) {
        return (
          <TextBlockAlternative
            title={data?.title}
            label={data?.label}
            link={data?.link}
            content={data?.content}
          />
        );
      }
      return (
        <TextBlock
          title={data?.title}
          subtitle={data?.subtitle}
          content={data?.content}
          label={data?.label}
          link={data?.link}
          theme={data?.theme}
          isIframe={data?.isIframe}
        />
      );
    case 'Bloc titre':
      return (
        <TitleBlock
          title={data?.title}
          theme={data?.theme}
        />
      );
    case 'Bloc recherche de résidence':
      return (
        <SearchBar
          cities={data?.cities}
          regions={data?.regions}
          departments={data?.departments}
          label={data?.title}
        />
      );
    default:
      return (
        <div className="container">
          <p>{`Bloc Inconnu (${type}) ⚠️`}</p>
        </div>
      );
  }
};

export default renderBlock;
